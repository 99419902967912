import viewport from './viewport';

export default {
  api: {
    contact: {
      url: 'https://mxiltcmlm4.execute-api.us-east-1.amazonaws.com/default/sendContactEmail',
    },
  },
  recaptcha: {
    key: '6LdfrH8dAAAAAA_z1kRYvonThNitiSSUdc5qu1MU',
  },
  viewport,
};
